html {
  margin: 0, 0, 0, 0;
}

.logo-textec {
  width: 400px;
  height: auto;
}
/***************************************************/
/* Menu vertical */
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.container {
  display: flex;
}

main {
  width: 100%;
  padding: 20px;
  margin-left: 20px;
}

.lb_login {
  color: white;
}

.sidebar {
  background: #6e959c;
  color: #fff;
  height: 100vh;
  position: fixed; /* Esto es importante para que el sidebar quede fijo en la pantalla */
  left: 0; /* Alinea el sidebar a la izquierda */
  top: 0; /* Opcional: Alinea el sidebar en la parte superior de la pantalla */
  max-height: 100vh; /* Establecer una altura máxima */
  overflow-y: auto; /* Permitir el desplazamiento vertical cuando el contenido excede la altura máxima */
  transition: all 0.5s;
}

.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}

.logo-menu {
  width: 50px;
  height: auto;
}

.bars {
  display: flex;
  font-size: 25px;
}

.link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none !important;
}

.linkAddActivity{
  color: white;
  text-decoration: none !important;
}


.link:hover {
  background: lightskyblue;
  color: #000;
  transition: all 0.5s;
}

.active {
  background: #fff;
  color: #000;
}

.icon,
.link_text {
  font-size: 17px;
  text-decoration: none;
}

.sidebar::-webkit-scrollbar {
  width: 6px; /* Ancho de la barra de desplazamiento */
  height: 2px; /* Altura de la barra de desplazamiento en modo horizontal */
}

.sidebar::-webkit-scrollbar-thumb {
  background: #6e959c; /* Color del botón de la barra de desplazamiento */
  border-radius: 3px; /* Borde redondeado del botón de la barra de desplazamiento */
}

/***************************************************/
/*Contenido*/

.contenido {
  background-color: #f4f4f4;
}

.sc-filter {
  margin-left: 5px;
  width: 100px;
}

.dOrdenPedido{
  white-space: pre-wrap;
}

.detalle-cliente-contenedor{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/***************************************************/

.chart-container {
  width: 800px;
  margin: 0 auto;
  margin-top: 20px;
}

.tabla-ventas {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 830px;
}

/*Titulo de las pantallas*/
.titulo-encabezado {
  font-family: "Montserrat", sans-serif;
  margin: 1rem;
  font-weight: 700;
}

/*Contenedor de las tarjetas de la pantalla de inicio*/
.information {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

/*Cuerpo de las tarjetas*/
.card-body {
  height: 115px;
  width: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 16px;
  margin: 1rem;
}

.card-body-reportes {
  height: 130px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 16px;
  margin: 1rem;
}

/*Titulo de las tarjetas*/
.card-title {
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 20px;
  font-weight: 700;
  width: 200px;
  text-align: center;
  padding-top: 20px;
}

/*Numero de la tarjeta*/
.card-number {
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 20px;
  font-weight: 400;
}

/*Contenedor del logo*/
.container-logo {
  display: flex;
  justify-content: center;
}

.weekly-section-container {
  margin-top: 20px;
  width: 350px;
}

.weekly-section-card {
  width: 100%; /* Cambia el ancho según sea necesario */
  margin: auto; /* Centra el card horizontalmente */
  transition: height 0.5s ease-in-out; /* Transición suave para la altura */
  overflow: hidden; /* Evitar que el contenido ocultado afecte el diseño */
  padding-bottom: 10px;
}

.weekly-section-card.expanded {
  height: auto !important; /* Establecer altura automática al expandirse */
}

.weekly-section-card-body {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra verticalmente */
}

.weekly-section-card-header {
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.card-title-weekly {
  width: 100%;
}

.weekly-section-button {
  margin-top: 10px;
}

.container-Weeks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

/***************************************************/
/*Mediciones*/

.cbtn_inven {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  flex-wrap: wrap;
}

.division {
  background-color: #6e959c;
  height: 2px;
}

.mediciones-filtro {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn-registrar,
.btn {
  background-color: #6e959c;
  color: white;
  width: 160px;
  height: 50px;
  border-radius: 15px;
  font-family: "Montserrat", sans-serif;
  font-size: medium;
  border: #6e959c;
}

.btn-eliminar,
.btnEdit-eliminar {
  /* Estilos comunes para el botón de eliminar */
  background-color: red;
  width: 150px;
  height: 40px;
  border-radius: 15px;
  font-family: "Montserrat", sans-serif;
  font-size: medium;
  border: none;
  color: white;
}

.btnEdit {
  /* Estilos comunes para el botón de eliminar */
  background-color: #6e959c;
  width: 150px;
  height: 40px;
  border-radius: 15px;
  font-family: "Montserrat", sans-serif;
  font-size: medium;
  border: none;
  color: white;
}

.btn-observar {
  background-color: #6e959c;
  color: white;
  width: 100px;
  height: 25px;
  border-radius: 15px;
  font-family: "Montserrat", sans-serif;
  font-size: medium;
  text-align: center;
  border: #6e959c;
}

.container-filtro {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.container-filtro-ordenes {
  padding-top: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}

.date-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.filtro-mediciones,
.filtro-pedidos {
  width: 400px;
  height: auto;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
}

.boton-mediciones {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.tabla-medidas {
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  font-family: "Montserrat", sans-serif;
}

.tabla-medidas th,
.tabla-medidas td {
  padding: 8px;
  border: 1px solid #ccc;
}

.tabla-medidas th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.tabla-medidas tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table-button-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.inpDates {
  margin-top: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.link-nombre {
  text-decoration: none;
  text-transform: uppercase; 
}

/*Nuevos modulos*/
.textech {
  font-family: "Montserrat", sans-serif;
  font-size: medium;
}

.btn-regresar {
  color: white;
  border: #6e959c;
  background-color: #6e959c;
  font-family: "Montserrat", sans-serif;
  border-radius: 20px;
  width: 100px;
  height: 25px;
}

/*Login*/
.login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 100px;
  padding: 20px;
  background-color: #6e959c;
  width: 500px;
  height: 350px;
  border-radius: 15px;
}

.form-login {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}

.icono-textech {
  height: auto;
  width: 80px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.btn-ingresar {
  background-color: white;
  color: #6e959c;
  width: 120px;
  height: 40px;
  border-radius: 15px;
  font-family: "Montserrat", sans-serif;
  font-size: medium;
  border: white;
}

.label-input {
  font-family: "Montserrat", sans-serif;
  font-size: larger;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding-bottom: 10px;
  color: white;
}

.form-check,
.form-check2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: -32px;
}

.checkboxContainer {
  gap: 20px;
}

/* Ocultar el checkbox predeterminado */
.form-check-input {
  display: none;
}

/* Estilo del checkbox personalizado */
.custom-checkbox {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  margin-left: 5px;
}

.custom-check {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border: 1px solid #000;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
    margin-left: 38px;
    padding-top: 0.5px;
}

/* Estilo del marcador dentro del checkbox personalizado */
.custom-checkbox::after {
  content: "\2714"; /* Código Unicode de una flecha (✔) */
  font-size: 16px;
  color: #007bff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none; /* Ocultar el marcador por defecto */
}

/* Cambiar el color del marcador cuando el checkbox está marcado */
.form-check-input:checked + .form-check-label .custom-checkbox::after {
  display: block; /* Mostrar el marcador cuando el checkbox está marcado */
}

/* Estilo del texto del label */
.form-check-label strong {
  color: #333333; /* Color del texto del label */
}

#username,
#password {
  width: 350px;
  text-align: center;
}

/*Registro Clientes*/

.form-contenedor {
  display: flex;
  flex-direction: row; /* Cambiamos a dirección de columna para evitar superposiciones */
  align-items: center; /* Alineamos los elementos al principio */
  font-family: "Montserrat", sans-serif;
}

.opciones-medidas {
  display: contents;
}

.form-registro-clientes,
.detalle-clientes {
  display: grid;
  gap: 10px;
  margin: 1rem;
}

.form-order-detail {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 1rem;
}

.div-inp,
.div-inp-detail {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.div-inp label {
  width: 152px;
  font-weight: bold;
}

.div-inp-detail label {
  width: 100px;
  font-weight: bold;
}

.div-inp-detail input {
  flex: 1;
  width: 180px;
  height: 24px;
  margin-right: 5px;
}

.div-inp input,
.div-inp select {
  flex: 1;
  width: 370px;
  height: 24px;
}

textarea {
  width: 370px;
}

.img-contenedor {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.botones-contenedor {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-wrap: wrap;
}

.isologo {
  height: auto;
  width: 150px;
}

/*Detalle Cliente*/
.contenedor-card-mediciones {
  width: fit-content;
}

.mediciones-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}

/*Detalle Medicion*/
.resultado-medida {
  margin-top: 0;
  margin-bottom: 0;
}

.lista-mediciones {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  font-size: larger;
  flex-wrap: nowrap;
}

.btn-transparente {
  background-color: transparent;
  border: none;
  color: #000; /* Color del texto */
  padding: 8px 16px; /* Ajusta el padding según sea necesario */
  font-size: 16px; /* Ajusta el tamaño de fuente según sea necesario */
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.pagination button {
  border: none;
  background-color: #f2f2f2;
  color: #333;
  padding: 8px 12px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 4px;
}

.pagination button.active {
  background-color: #007bff;
  color: #fff;
}

.icon-nav-menu {
  height: 40px;
  width: auto;
}

/* CSS personalizado para ocultar/en mostrar enlaces de texto e iconos */
.navbar-nav .nav-link .text {
  display: inline; /* Mostrar texto */
}

.navbar-nav .nav-link .icon {
  display: none; /* Ocultar iconos */
}

/* Estilo para el botón hamburguesa personalizado */
.custom-toggler .navbar-toggler-icon {
  background-color: transparent;
}

/* Estilo para el contenedor del checkbox (el label) */
.lb-checkbox {
  display: flex;
  align-items: center;
}

/* Estilo para el contenedor del switch */
.form-switch {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
}

/* Estilo para el texto del switch */
.form-check-label {
  margin-left: 10px; /* Espacio entre el switch y el texto (ajusta según sea necesario) */
}

/* Estilo para el switch en sí */
.form-check-input {
  width: 40px; /* Ancho del switch */
  height: 20px; /* Altura del switch */
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #ccc;
  border-radius: 20px; /* Bordes redondeados para crear la forma del switch */
  outline: none;
  background-color: #fff; /* Color de fondo del switch cuando está apagado */
}

/* Estilo para el switch cuando está marcado (encendido) */
.form-check-input:checked {
  background-color: #007bff; /* Color de fondo del switch cuando está encendido */
  border-color: #007bff; /* Color del borde del switch cuando está encendido */
}

/* Estilo para el indicador del switch (el punto o círculo) */
.form-check-input:checked::before {
  content: "";
  position: absolute;
  width: 20px; /* Tamaño del indicador */
  height: 16px; /* Tamaño del indicador */
  background-color: #fff; /* Color del indicador cuando está encendido */
  border-radius: 50%; /* Hacer que el indicador sea un círculo */
  top: 50%;
  left: 4px; /* Ajusta la posición del indicador dentro del switch */
  transform: translateY(-50%);
  transition: left 0.2s ease-in-out;
}

/* Estilo para el indicador del switch cuando está marcado (encendido) */
.form-check-input:checked::before {
  left: 20px; /* Mueve el indicador a la posición de encendido */
}

/* Estilo para el texto del label */
label {
  font-size: 16px;
  color: #333;
}

/* Estilo para el texto del label cuando el checkbox está marcado */
input[type="checkbox"]:checked + label {
  color: #007bff; /* Cambiar el color del texto cuando está marcado */
}

.btn-agregar-detalle {
  margin-top: 10px;
  background-color: #6e959c;
  color: white;
  width: 200px;
  height: 50px;
  border-radius: 15px;
  font-family: "Montserrat", sans-serif;
  font-size: medium;
  border: #6e959c;
}

.swal2-popup-payment {
  max-width: 500px;
  margin: 0 auto;
  background-color: #fff;
  border: 2px solid #6e959c;
  border-radius: 5px;
}

.swal2-title-payment {
  color: #6e959c;
}

.swal2-input-payment,
.swal2-textarea-payment,
.swal2-select-payment {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.swal2-buttons-payment {
  display: flex;
  justify-content: center;
}

.swal2-button-payment {
  background-color: #6e959c;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.swal2-button-payment:hover {
  background-color: #6e959c;
}

.transparent-btn {
  opacity: 0; /* Define el nivel de opacidad deseado (0 a 1) */
  background: none; /* Elimina el fondo del botón */
  border: none; /* Elimina el borde del botón */
  cursor: pointer; /* Cambia el cursor al pasar sobre el botón */
}

.cbTiroLargoYa{
  margin-left: 42px;
}



/* Mostrar iconos y ocultar texto en Navbar colapsado */
@media (max-width: 992px) {
  .navbar-nav .nav-link .text {
    display: none; /* Ocultar texto */
  }

  .navbar-nav .nav-link .icon {
    display: inline; /* Mostrar iconos */
  }

  .container-Weeks {
    justify-content: center;
  }

  textarea {
    width: 254px;
  }
}

@media (max-width: 760px) {

  .cbTiroLargoYa{
    margin-left: 5px;
  }

  .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  main {
    margin-left: 25px;
  }

  .detalle-cliente-contenedor{
    text-align: center;
  }


  .botones-contenedor {
    flex-direction: column;
  }

  .form-contenedor {
    justify-content: center;
    flex-direction: column-reverse;
  }

  .detail-container {
    flex-direction: column;
  }

  .division {
    width: -1px;
  }

  .login {
    width: auto;
    margin-left: 5px;
    margin-right: 5px;
  }

  #txtArea {
    width: 207px;
  }

  .div-inp {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .div-inp label {
    width: 215px;
    text-align: center;
  }

  .div-inp input,
  textarea,
  #empresa,
  #producto,
  .div-inp-detail input {
    width: 256px !important;
    text-align: center;
    color: black;
  }

  .tabla-medidas table {
    width: 100%;
    margin-left: 0;
  }

  .tabla-medidas thead {
    display: none;
  }

  .tabla-medidas td {
    display: block;
    text-align: center;
  }

  .tabla-medidas td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
  }

  .filtro-pedidos,
  .filtro-mediciones {
    width: 300px;
  }

  .btn-agregar-detalle {
    margin: auto;
    margin-top: 10px;
  }

  .cbtn_inven {
    flex-direction: column;
    align-items: center;
  }

  .chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 350px;
    height: auto;
  }
  .container-Weeks {
    justify-content: center;
  }

  #prenda{
    width: 256px;
  }
}
